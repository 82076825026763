import { IIrisAppSettings } from '../interface/IIrisAppSettings';

export const sit: IIrisAppSettings = {
  cdnBaseUrl: 'https://irisstorageaccdev.z5.web.core.windows.net/iris-ux',
  serviceEndPoints: [
    {
      clientId: '45233183-9a0c-4750-982d-541e2112aafe',
      serviceEndPoint: 'https://iris-api-dev.azurewebsites.net/api/',
    },
    {
      clientId: '00000003-0000-0000-c000-000000000000',
      serviceEndPoint: 'https://graph.microsoft.com/',
    }
  ],
  IrisAppConfiguration: {
    'irisApiBaseUrl': 'https://iris-api-dev.azurewebsites.net/api/',
    'graphApiUrl': 'https://graph.microsoft.com/v1.0',
    'dashboardUrl': '/#/dashboard',
    'enableLookAhead': 'true'
  }
};
