import { IAppService, IFxpAppContext, IServiceEndPoints, IRouteInfo, IPartnerBundle, IAuthExceptionServiceEndPoints } from '@fxp/fxpservices';
import { appSettings } from './environments/common/appsettingsGenerator';
import IrisAppConstants from './app/common/app.constants';
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class IrisApp_routes implements IAppService {
  getRoutes(fxpAppContext: IFxpAppContext): IRouteInfo {
    const routeInfo: IRouteInfo = {
      applicationName: IrisAppConstants.AppName,
      sharedBundles: ['Iris-Bundle'],
      routes: [
        {
          name: 'iris-launcher',
          url: '/iris-launcher',

generatedBundle: '0670f6fd3d7f-IrisAppModule-Bundle',
          data: {
            headerName: 'Iris',
            appNameForTelemetry: 'Iris',
            partnerTelemetryName: 'Iris',
            breadcrumbText: 'Iris',
            pageTitle: 'Iris',
            featureName: 'Iris',
            actionName : 'LaunchIris',
            style: 'icon icon-people'
          },
          includeRootModule: true
        },
        {
          name: 'iris-webhook',
          url: '/iris-webhook',

generatedBundle: '0670f6fd3d7f-IrisAppModule-Bundle',
          data: {
            headerName: 'Iris',
            appNameForTelemetry: 'Iris',
            partnerTelemetryName: 'Iris',
            breadcrumbText: 'Iris',
            pageTitle: 'Iris',
            featureName: 'Iris',
            actionName : 'LaunchIris',
            style: 'icon icon-people'
          },
          includeRootModule: true
        },
        {
          name: 'supportrequest',
          url: '/supportrequest',

generatedBundle: '0670f6fd3d7f-IrisAppModule-Bundle',
          data: {
            headerName: 'Operation Requests',
            appNameForTelemetry: 'Iris',
            partnerTelemetryName: 'Iris',
            breadcrumbText: 'My Operation Requests',
            pageTitle: 'My Operation Requests',
            featureName: 'OperationRequests',
            actionName : 'ViewOperationRequests',
            style: 'icon icon-people'
          }
        },
        {
          name: 'utilityrequest',
          url: '/utilityrequest',

generatedBundle: '0670f6fd3d7f-IrisAppModule-Bundle',
          data: {
            headerName: 'Operation Requests',
            appNameForTelemetry: 'Iris',
            partnerTelemetryName: 'Iris',
            breadcrumbText: 'Supported Operation Requests',
            pageTitle: 'Supported Operation Requests',
            featureName: 'OperationRequests',
            actionName : 'SuppertedOperationRequests',
            style: 'icon icon-people'
          }
        },
        {
          name: 'adminnotification',
          url: '/adminnotification',

generatedBundle: '0670f6fd3d7f-IrisAppModule-Bundle',
          data: {
            headerName: 'Admin Notification',
            appNameForTelemetry: 'Iris',
            partnerTelemetryName: 'Iris',
            breadcrumbText: 'Admin Notification',
            pageTitle: 'Admin Notification',
            featureName: 'AdminNotification',
            actionName : 'AdminNotification',
            style: 'icon icon-people'
          }
        }
      ]
    };
    return routeInfo;
  }

  getServiceEndPoints(): Array<IServiceEndPoints> {
    return appSettings().serviceEndPoints;
  }

  getAuthExceptionEndpoints(): IAuthExceptionServiceEndPoints[] {
    const authExceptionEndpoints: IAuthExceptionServiceEndPoints[] = [];

    return authExceptionEndpoints;
  }

  public getBundles(): Array<IPartnerBundle> {
    const baseUrl = appSettings().cdnBaseUrl;
    const bundlesData = [
      {
        name: 'Iris-Bundle',
        files: [
          'https://cdn.botframework.com/botframework-webchat/latest/webchat.js'
        ],
        sequentialLoading: true
      }
    ];
    return bundlesData;
  }
  public getGeneratedBundles() {
    var baseUrl = '';
    var currentScriptUrl = document.currentScript['src'];
    if (currentScriptUrl.indexOf('exclude.inline.bundle') > 0) {
      currentScriptUrl = Error().stack.match(/(https?:\/\/[^ ]*)/g);
      baseUrl = currentScriptUrl
        .filter((item) => item.indexOf('localhost:5000') === -1)[0]
        .toLowerCase();
      baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    } else {
      baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/'));
    }
    if (baseUrl.endsWith("/")){
        baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    }
    return [
            {
                name: '0670f6fd3d7f-IrisAppModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/IrisAppModule.vendor.bundle.js',
                    baseUrl + '/IrisAppModule.bundle.js'
                ],
                sequentialLoading: true           
            },

    ]
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(IrisApp_routes, 'IrisBotApp');
