import { IIrisAppSettings } from '../interface/IIrisAppSettings';

import { aurorasit } from '../configs/appSettings.aurorasit';
import { dev } from '../configs/appSettings.dev';
import { prod } from '../configs/appSettings.prod';
import { sit } from '../configs/appSettings.sit';
import { uat } from '../configs/appSettings.uat';

import { Environment } from '@fxp/fxpservices';

export const appSettings = (): IIrisAppSettings => {
  let environmentAppSetting: IIrisAppSettings;
  switch (window['FxpAppSettings'].EnvironmentName) {
    case Environment.Dev: environmentAppSetting = dev; break;
    case Environment.Sit: environmentAppSetting = sit; break;
    case Environment.Uat: environmentAppSetting = uat; break;
    case Environment.AuroraSit: environmentAppSetting = aurorasit; break;
    case Environment.Prod: environmentAppSetting = prod; break;
    default: environmentAppSetting = dev; break;
  }
  return environmentAppSetting;
};
