import { IIrisAppSettings } from '../interface/IIrisAppSettings';

export const uat: IIrisAppSettings = {
  cdnBaseUrl: 'https://irisstorageppe.z5.web.core.windows.net/iris-ux',
  serviceEndPoints: [
    {
      clientId: '5b77772e-780a-44be-b7ef-0471ca271cfb',
      serviceEndPoint: 'https://iris-api-ppe.azurewebsites.net/api/',
    },
    {
      clientId: '00000003-0000-0000-c000-000000000000',
      serviceEndPoint: 'https://graph.microsoft.com/',
    }
  ],
  IrisAppConfiguration: {
    'irisApiBaseUrl': 'https://iris-api-ppe.azurewebsites.net/api/',
    'graphApiUrl': 'https://graph.microsoft.com/v1.0',
    'dashboardUrl': '/#/opdashboard',
    'enableLookAhead': 'true'
  }
};
