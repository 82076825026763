export default class IrisAppConstants {
    public static readonly AppName = 'IrisBotApp';
    public static readonly BotAvatarImageUrl = '/assets/images/chatbotAvatar.png';
    public static readonly NewBotAvatarImagePath = '/assets/images/iris-modal-icon.svg';
    public static readonly AttachmentIconPath = '/assets/images/attachment-icon.svg';
    public static readonly SendIconPath = '/assets/images/send-icon.svg';
    public static readonly AgentAvatarImageUrl = '/assets/images/icon-agent.png';
    public static readonly LoadingImageUrl = '/assets/images/loading.gif';
    public static readonly CreateSupportTicket = 'Create Support Ticket';
    public static readonly HelpButtonUrl = 'https://microsoft.sharepoint.com/teams/AnswersHub/SitePages/IntelligentSupport.aspx';
    public static readonly InstallTeamsUrl = 'https://teams.microsoft.com/l/app/d31e002e-6b41-4ef8-80d9-be7fb1a33e45';
    public static readonly WhatsNewUrl = 'https://microsoft.sharepoint.com/teams/AnswersHub/SitePages/WhatsNewinIris-.aspx';
    public static readonly SelfAssistedSupportRequest = 'SELFASSISTEDSUPPORTREQUEST';
    public static readonly HelpOntheSpot = 'HELPONTHESPOT';
    public static readonly DeepLinking = 'DEEPLINK';
    public static readonly TextInputPlaceholder = 'Ask a work question or make a request'
}
